import React from 'react';

import { GiftcardDetailItem } from '@components/GiftcardDetailItem/GiftcardDetailItem';
import { GiftCardCheckoutMessages } from '@customTypes/Messages';
import { ApiGiftCardOrderResponse } from '@customTypes/apiCompound';
import { Package } from '@customTypes/apiTypes';
import { PricePresentational } from '@customTypes/common';
import { dataLayerV2Hooks } from '@hooks/dataLayerV2Hooks/dataLayerV2Hooks';
import { dataMappingHooks } from '@hooks/dataMappingHooks/dataMappingHooks';
import { entityHooks } from '@hooks/entityHooks/entityHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';

export const hooks = {
  useMapCartOrderItemById(
    itemId: string,
    basket: ApiGiftCardOrderResponse | null,
  ) {
    const allPackages = dataMappingHooks.useFlatPackagesFromOrder(basket);

    const filteredPackageArr =
      allPackages.map((packageElem) => {
        const filteredItem = packageElem?.items?.filter(item => item.id === itemId);
        const filteredExtraItem =
          packageElem?.extraItems?.filter(item => item.id === itemId);

        if (filteredItem && filteredItem.length) {
          const filteredPackage = {
            ...packageElem,
            extraItems: [],
            items: filteredItem,
          } as Package;

          return filteredPackage;
        } else if (filteredExtraItem && filteredExtraItem.length) {
          const filteredPackage = {
            ...packageElem,
            extraItems: filteredExtraItem,
            items: [],
          } as Package;

          return filteredPackage;
        } else {
          return null;
        }
      }).filter(arr => arr) as Package[];

    const { mappedItemsV2: items } = dataMappingHooks.useMapAllShipmentsV2(filteredPackageArr);

    return {
      allPackages,
      filteredPackageArr,
      items,
    };
  },
  useDeleteOrderItemHandler(
    id: string,
    onDeleteOrderItem: (itemID: string) => void,
  ) {
    const basket = messageHooks.useSubjectState(GiftCardCheckoutMessages.GiftcardBasket);
    const { filteredPackageArr, items } = hooks.useMapCartOrderItemById(id, basket);
    const currency = filteredPackageArr?.[0]?.items?.[0]?.unitPrice.currency;
    const trackRemoveFromCartV2 = dataLayerV2Hooks.useTrackRemoveFromCartV2();
    const clearDataLayer = dataLayerV2Hooks.useClearDataLayer();
    const tax = basket?.data.order?.orderTaxes.reduce((prev, cur) =>
      prev + cur.taxAmount.value, 0) ?? 0;
    const value = items?.reduce((prev, curr) =>
      prev + (Number(curr.price) * curr.quantity), 0) ?? 0;

    return React.useCallback(() => {
      onDeleteOrderItem(id);
      clearDataLayer();
      currency && items && trackRemoveFromCartV2({
        currency,
        value: value - tax,
        items,
      });
    }, [value, tax, id, currency, items, onDeleteOrderItem, trackRemoveFromCartV2, clearDataLayer]);
  },
  useUnDeleteOrderItemDataLayer(
    restoredIdRef: React.MutableRefObject<string | null>,
  ) {
    const basket = messageHooks.useSubjectState(GiftCardCheckoutMessages.GiftcardBasket);
    const { filteredPackageArr, items } =
      hooks.useMapCartOrderItemById(restoredIdRef.current || '', basket);
    const currency = filteredPackageArr?.[0]?.items?.[0]?.unitPrice.currency;
    const trackAddToCartV2 = dataLayerV2Hooks.useTrackAddToCartV2();
    const clearDataLayer = dataLayerV2Hooks.useClearDataLayer();

    if (currency && items) {
      const tax = basket?.data.order?.orderTaxes.reduce((prev, cur) =>
        prev + cur.taxAmount.value, 0) ?? 0;
      const value = items?.reduce((prev, curr) =>
        prev + (Number(curr.price) * curr.quantity), 0) ?? 0;
      clearDataLayer();
      trackAddToCartV2({
        currency,
        value: value - tax,
        items,
      });
      restoredIdRef.current = null;
    }
  },
};

export type Props = {
  disabled: boolean;
  id: string;
  isDeleted?: boolean;
  label: React.ReactNode;
  onDeleteItem: (itemID: string) => void;
  onUnDeleteItem: (id: string, itemSKU: string, personalMessageText: string | null) => void;
  personalMessageText: string | null;
  price: PricePresentational;
  restoredIdRef: React.MutableRefObject<string | null>;
  sku: string;
};

export const GiftcardDetailItemContainer = (props: Props) => {
  const {
    id,
    onDeleteItem,
    restoredIdRef,
  } = props;

  const handleDeleteItem = hooks.useDeleteOrderItemHandler(id, onDeleteItem);

  hooks.useUnDeleteOrderItemDataLayer(restoredIdRef);

  const entity = entityHooks.useHotelGiftCardPageEntity();
  const defaultWrapperSku: string | null = React.useMemo(() => (
    entity?.wrappersProductsList?.[0]?.variations[0].sku || null
  ), [entity?.wrappersProductsList]);

  return (
    <GiftcardDetailItem
      {...props}
      handleDeleteItem={handleDeleteItem}
      defaultWrapperSku={defaultWrapperSku}
    />
  );
};

GiftcardDetailItemContainer.displayName = 'GiftcardDetailItemContainer';
