import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { callbackHooks } from '@nxte-nl/callback-hooks/src/callbackHooks';

import { GiftcardDetailItemContainer }
  from '@containers/GiftcardDetailItemContainer/GiftcardDetailItemContainer';
import { GiftCardType } from '@customTypes/apiEnums';
import { PricePresentational } from '@customTypes/common';

import { AnimatedShowMore } from '../AnimatedShowMore/AnimatedShowMore';
import { Button } from '../Button/Button';
import { GiftcardInfoLine } from '../GiftcardInfoLine/GiftcardInfoLine';
import { NumberPicker } from '../NumberPicker/NumberPicker';
import { Typography } from '../Typography/Typography';
import styles from './GiftcardBasketItem.module.css';

const messages = defineMessages({
  showMore: {
    id: 'src.components.GiftcardBasketItem.showMore',
    defaultMessage: 'Bekijk details',
  },
  showLess: {
    id: 'src.components.GiftcardBasketItem.showLess',
    defaultMessage: 'Verberg details',
  },
  deletePackageBtn: {
    id: 'src.components.GiftcardBasketItem.deletePackageBtn',
    defaultMessage: 'Verwijder',
  },
});

const giftcardPackageChangeDebounceMs = 200;

export const hooks = {
  useChangePackageHandler(
    item: GiftCardBasketItem,
    onGiftcardPackageChange: (giftCardBasketItem: GiftCardBasketItem) => void,
  ) {
    return React.useCallback((quantity: number) => {
      onGiftcardPackageChange({ ...item, quantity });
    }, [item, onGiftcardPackageChange]);
  },
  useAdditionsWithDeletedState() {
    return React.useState<Addition[]>([]);
  },
  useAdditionsMap(
    additions: Addition[],
    setAdditionsWithStatus: React.Dispatch<React.SetStateAction<Addition[]>>,
  ) {
    const restoredIdRef = React.useRef<string | null>(null);

    React.useEffect(() => {
      const itemsHashMap = additions.reduce<Record<string, Addition>>(
        (prev, curr) => ({ ...prev, [curr.id]: curr }), {},
      );

      setAdditionsWithStatus((prevState) => {
        if (prevState.length) {
          return prevState.map((item) => {
            if (item.status === Status.Restoring) {
              const stateHashMap = prevState.reduce<Record<string, Addition>>(
                (prev, curr) => ({ ...prev, [curr.itemID]: curr }), {},
              );
              const restoredItem = additions.find((i) => (
                !stateHashMap[i.itemID] && item.type === i.type
              ));
              if (restoredItem) {
                restoredIdRef.current = restoredItem.itemID;
                return { ...restoredItem, status: Status.Holding };
              }
            } else if (item.status === Status.Deleting) {
              return { ...item, status: Status.Deleted };
            } else if (item.status === Status.Deleted) {
              return item;
            }

            return { ...itemsHashMap[item.id], status: Status.Holding };
          });
        }
        return additions.map((item) => ({ ...item, status: Status.Holding }));
      });
    }, [additions, setAdditionsWithStatus]);

    return {
      restoredIdRef,
    };
  },
  useDeleteAdditionHandler(
    setAdditionsWithStatus: React.Dispatch<React.SetStateAction<Addition[]>>,
    onDeleteOrderItem: (itemID: string) => void,
  ) {
    return React.useCallback((itemID: string) => {
      setAdditionsWithStatus((prevState) => {
        return prevState.map((item) => ({
          ...item,
          status: item.itemID === itemID ? Status.Deleting : item.status,
        }));
      });

      onDeleteOrderItem(itemID);
    }, [onDeleteOrderItem, setAdditionsWithStatus]);
  },
  useUnDeleteAdditionHandler(
    packageItem: GiftCardBasketItem,
    setAdditionsWithStatus: React.Dispatch<React.SetStateAction<Addition[]>>,
    onUnDeleteOrderItem:
      (itemSKU: string, personalMessageText: string | null, packageID: string) => void,
  ) {
    return React.useCallback((id: string, itemSKU: string, personalMessageText: string | null) => {
      setAdditionsWithStatus((prevState) => {
        return prevState.map((item) => ({
          ...item,
          status: item.itemID === id ? Status.Restoring : item.status,
        }));
      });

      onUnDeleteOrderItem(
        itemSKU,
        personalMessageText,
        packageItem.id,
      );
    }, [packageItem, onUnDeleteOrderItem, setAdditionsWithStatus]);
  },
};

export enum Status {
  Deleted = 'deleted',
  Deleting = 'deleting',
  Holding = 'holding',
  Restoring = 'restoring',
}

export type Addition = {
  id: string;
  itemID: string;
  personalMessageText?: string | null;
  price: PricePresentational;
  sku: string;
  status?: Status;
  title: string;
  type: string;
};

export type GiftCardBasketItem = {
  additions: Addition[];
  extraProductsSKUs: string[] | null;
  giftCardSKU: string;
  giftCardType: GiftCardType;
  giftPackingSKU: string | null;
  id: string;
  packagePrice?: PricePresentational;
  personalMessageSKU: string | null;
  personalMessageText: string | null;
  price: PricePresentational;
  quantity: number;
  status?: Status;
  title: string;
  wrapperSKU: string | null;
};

export type Props = {
  disabled: boolean;
  handleDeleteGiftcardPackage: () => void;
  onDeleteOrderItem: (itemID: string) => void;
  onGiftcardPackageChange: (giftCardBasketItem: GiftCardBasketItem) => void;
  onUnDeleteOrderItem: (id: string, itemSKU: string, personalMessageText: string | null) => void;
  packageItem: GiftCardBasketItem;
};

export const GiftcardBasketItem = React.memo((props: Props) => {
  const {
    packageItem,
    disabled,
    handleDeleteGiftcardPackage,
    onGiftcardPackageChange,
    onDeleteOrderItem,
    onUnDeleteOrderItem,
  } = props;
  const intl = useIntl();

  const expandLinkContent = {
    more: <FormattedMessage
      {...messages.showMore} />,
    less: <FormattedMessage
      {...messages.showLess} />,
  };

  const [additionsWithStatus, setAdditionsWithStatus] =
    hooks.useAdditionsWithDeletedState();

  const handlePackageChange =
    hooks.useChangePackageHandler(packageItem, onGiftcardPackageChange);

  const { restoredIdRef } = hooks.useAdditionsMap(
    packageItem.additions, setAdditionsWithStatus,
  );

  const handleDeleteAddition =
    hooks.useDeleteAdditionHandler(setAdditionsWithStatus, onDeleteOrderItem);

  const handleUnDeleteAddition =
    hooks.useUnDeleteAdditionHandler(packageItem, setAdditionsWithStatus, onUnDeleteOrderItem);

  return (
    <div className={styles.giftcardBasketItemWrapper}>
      <div className={styles.topBlock}>
        <div className={styles.giftcardBasketItem}>
          <GiftcardInfoLine
            title={packageItem.title}
            quantity={packageItem.quantity}
            bold={true}
            titleClassname={styles.text} />
          <div>
            <Typography className={styles.text} >
              { intl.formatNumber(
                packageItem.packagePrice?.value ?? 0,
                { style: 'currency', currency: packageItem.packagePrice?.currency || '' },
              ) }
            </Typography>
          </div>
        </div>
        <AnimatedShowMore
          defaultCount={0}
          expandLinkContent={expandLinkContent}
          toggleButtonClass={styles.showMoreButton}
          filtersWrapperClass={styles.detailsContent}
        >
          {additionsWithStatus.map((addition) => (
            <GiftcardDetailItemContainer
              key={addition.id}
              id={addition.itemID}
              sku={addition.sku}
              personalMessageText={addition.personalMessageText || null}
              label={addition.title}
              price={addition.price}
              onDeleteItem={handleDeleteAddition}
              onUnDeleteItem={handleUnDeleteAddition}
              isDeleted={addition?.status === 'deleted' || addition?.status === 'restoring'}
              restoredIdRef={restoredIdRef}
              disabled={disabled}
            />
          ))}
        </AnimatedShowMore>
      </div>
      <div className={styles.bottomBlock}>
        <Button
          className={styles.deleteBtn}
          intent='tertiaryV2'
          size='medium'
          color='blue'
          disabled={disabled}
          onClick={handleDeleteGiftcardPackage}
        >
          <FormattedMessage {...messages.deletePackageBtn} />
        </Button>
        <NumberPicker
          value={packageItem.quantity}
          onChanged={
            callbackHooks.useDebounce(handlePackageChange, giftcardPackageChangeDebounceMs)}
          min={1}
          max={200}
          size='small'
          disabled={disabled}
        />
      </div>
    </div>
  );
});

GiftcardBasketItem.displayName = 'GiftcardBasketItem';
