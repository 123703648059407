import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import { Button } from '../Button/Button';
import styles from './UndoBlock.module.css';

const messages = defineMessages({
  undoButton: {
    id: 'src.components.UndoBlock.undoButton',
    defaultMessage: 'Ongedaan maken',
  },
});

export type Props = {
  className?: string;
  disabled: boolean;
  onUndo: () => void;
  text: string | JSX.Element;
};

export const UndoBlock = React.memo((props: Props) => {
  const { text, onUndo, className, disabled } = props;

  return (
    <div className={classNames(styles.undoBlock, className)}>
      <div className={styles.undoText}>
        {text}
      </div>
      <Button
        intent='tertiaryV2'
        size='medium'
        onClick={onUndo}
        className={styles.undoButton}
        disabled={disabled}
      >
        <FormattedMessage {...messages.undoButton} />
      </Button>
    </div>
  );
});

UndoBlock.displayName = 'UndoBlock';
