import React from 'react';

import {
  GiftCardBasketItem,
  Status,
} from '@components/GiftcardBasketItem/GiftcardBasketItem';

export const undoBasketHooks = {
  useDeletePackages(
    setItemsWithStatus: React.Dispatch<React.SetStateAction<GiftCardBasketItem[] | null>>,
    onDeleteGiftcardPackage: (packageId: string) => void,
  ) {
    const onDeletePackage = React.useCallback((giftCardBasketItem: GiftCardBasketItem) => {
      setItemsWithStatus((prevState) => {
        if (prevState) {
          return prevState.map((item) => ({
            ...item,
            status: item.id === giftCardBasketItem.id ? Status.Deleting : item.status,
          }));
        }
        return null;
      });

      onDeleteGiftcardPackage(giftCardBasketItem.id);
    }, [setItemsWithStatus, onDeleteGiftcardPackage]);

    return { onDeletePackage };
  },
  useUnDeletePackages(
    setItemsWithStatus: React.Dispatch<React.SetStateAction<GiftCardBasketItem[] | null>>,
    onUnDeleteGiftcardPackage: (giftCardBasketItem: GiftCardBasketItem) => void,
  ) {
    const onUnDeletePackage = React.useCallback((giftCardBasketItem: GiftCardBasketItem) => {
      setItemsWithStatus((prevState) => {
        if (prevState) {
          return prevState.map((item) => ({
            ...item,
            status: item.id === giftCardBasketItem.id ? Status.Restoring : item.status,
          }));
        }
        return null;
      });

      onUnDeleteGiftcardPackage(giftCardBasketItem);
    }, [setItemsWithStatus, onUnDeleteGiftcardPackage]);

    return { onUnDeletePackage };
  },
  useItemsMap(
    items: GiftCardBasketItem[] | null,
    setItemsWithStatus: React.Dispatch<React.SetStateAction<GiftCardBasketItem[] | null>>,
  ) {
    const restoredIdRef = React.useRef<string | null>(null);

    React.useEffect(() => {
      const itemsHashMap = (items ?? []).reduce<Record<string, GiftCardBasketItem>>(
        (prev, curr) => ({ ...prev, [curr.id]: curr }), {},
      );

      setItemsWithStatus((prevState) => {
        if (prevState) {
          return prevState.map((item) => {
            if (item.status === Status.Restoring) {
              const stateHashMap = prevState.reduce<Record<string, GiftCardBasketItem>>(
                (prev, curr) => ({ ...prev, [curr.id]: curr }), {},
              );
              const restoredItem = items?.find((i) => !stateHashMap[i.id]);
              if (restoredItem) {
                restoredIdRef.current = restoredItem.id;
                return { ...restoredItem, status: Status.Holding };
              }
            } else if (item.status === Status.Deleting) {
              return { ...item, status: Status.Deleted };
            } else if (item.status === Status.Deleted) {
              return item;
            }

            return { ...(itemsHashMap[item.id] ?? item), status: Status.Holding };
          });
        }
        return items?.map((item) => ({ ...item, status: Status.Holding })) ?? null;
      });
    }, [items, setItemsWithStatus]);

    return {
      restoredIdRef,
    };
  },
};
