import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import classNames from 'classnames';

import { PricePresentational } from '@customTypes/common';

import { UndoBlock } from '../UndoBlock/UndoBlock';
import { Close } from '../icons/Close/Close';
import styles from './GiftcardDetailItem.module.css';

const messages = defineMessages({
  undoText: {
    id: 'src.components.GiftcardDetailItem.undoText',
    defaultMessage: '{title} is verwijderd.',
  },
});

export const hooks = {
  useUnDeleteOrderItemHandler(
    id: string,
    sku: string,
    personalMessageText: string | null,
    onUnDeleteOrderItem: (id: string, itemSKU: string, personalMessageText: string | null) => void,
  ) {
    return React.useCallback(() => {
      onUnDeleteOrderItem(id, sku, personalMessageText);
    }, [onUnDeleteOrderItem, id, sku, personalMessageText]);
  },
};

export type Props = {
  defaultWrapperSku: string | null;
  disabled: boolean;
  handleDeleteItem: () => void;
  id: string;
  isDeleted?: boolean;
  label: React.ReactNode;
  onUnDeleteItem: (id: string, itemSKU: string, personalMessageText: string | null) => void;
  personalMessageText: string | null;
  price: PricePresentational;
  sku: string;
};

export const GiftcardDetailItem = React.memo((props: Props) => {
  const {
    id,
    sku,
    personalMessageText,
    label,
    price,
    handleDeleteItem,
    onUnDeleteItem,
    isDeleted,
    defaultWrapperSku,
    disabled,
  } = props;
  const intl = useIntl();

  const handleUnDeleteItem =
    hooks.useUnDeleteOrderItemHandler(id, sku, personalMessageText, onUnDeleteItem);

  const dataTestId = React.useMemo(() => {
    if (typeof label !== 'string') return undefined;
    return label.replaceAll(' ', '-');
  }, [label]);

  const formattedPrice = intl.formatNumber(
    price.value,
    { style: 'currency', currency: price.currency },
  );

  return (
    <div data-testid={dataTestId} className={classNames({
      [styles.firstOfOrder]: defaultWrapperSku === sku,
    })}>
      {
        isDeleted
          ? <UndoBlock
            className={styles.undoBlock}
            text={
              <FormattedMessage
                values={{ title: label }}
                {...messages.undoText}
              />
            }
            onUndo={handleUnDeleteItem}
            disabled={disabled}
          />
          : (
            <div className={styles.giftcardDetailItem}>
              <div className={classNames(styles.texts, styles.label)}>
                {label}
              </div>
              <div className={styles.group}>
                <div className={classNames(styles.texts, styles.price)}>
                  {`+ ${formattedPrice}`}
                </div>
                {
                  defaultWrapperSku !== sku
                    ? <Close onClick={handleDeleteItem} className={styles.close} />
                    : null
                }
              </div>
            </div>
          )
      }
    </div>
  );
});

GiftcardDetailItem.displayName = 'GiftcardDetailItem';
