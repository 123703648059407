import React from 'react';

import {
  DataLayerEcommerceGiftCardProductV3,
  DataLayerGiftCardProductV2,
  DataLayerGiftCardProductV3,
  PaymentInfo,
  PurchaseInfo,
  QuotationUserInfo,
} from '@customTypes/DataLayer';
import { Currency } from '@customTypes/apiEnums';
import { GiftCardType } from '@customTypes/apiEnums';
import { Customer } from '@customTypes/apiTypes';

export const callbacks = {
  async hashSha256(val: string | null) {
    if (!val) return null;
    const utf8 = new TextEncoder().encode(val);
    const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');
  },
};

export const dataLayerV2Hooks = {
  useTrackViewItem(items: DataLayerGiftCardProductV2[] | null) {
    React.useEffect(() => {
      if (items) {
        window.dataLayer.push({
          event: 'view_item',
          ecommerce: {
            currency: items[0].currency || Currency.EUR,
            items: items,
          },
        });
      }
    }, [items]);
  },
  useTrackAddToCartV2() {
    return React.useCallback((ecommerce: DataLayerEcommerceGiftCardProductV3 | null) => {
      if (ecommerce) {
        window.dataLayer.push({
          event: 'add_to_cart',
          ecommerce,
        });
      }
    }, []);
  },
  useViewCart() {
    return React.useCallback((ecommerce: DataLayerEcommerceGiftCardProductV3 | null) => {
      if (ecommerce) {
        window.dataLayer.push({
          event: 'view_cart',
          ecommerce,
        });
      }
    }, []);
  },
  useTrackBeginCheckout(
    items: DataLayerGiftCardProductV3[] | null,
    value: number,
    currency: Currency,
  ) {
    return React.useCallback(() => {
      if (items) {
        window.dataLayer.push({
          event: 'begin_checkout',
          ecommerce: {
            currency: currency,
            value: value,
            items: items,
          },
        });
      }
    }, [items, currency, value]);
  },
  useTrackSelectItem(buttonId: string, buttonTitle: string, url?: string) {
    return React.useCallback(() => {
      if (url?.includes('buy') || url === undefined) {
        window.dataLayer.push({
          event: 'select_item',
          cta_button_text: buttonTitle,
          cta_button_id: buttonId,
        });
      }
    }, [buttonId, buttonTitle, url]);
  },
  useTrackAddShipping(ecommerce: DataLayerEcommerceGiftCardProductV3 | null) {
    return React.useCallback(() => {
      if (ecommerce) {
        window.dataLayer.push({
          event: 'add_shipping_info',
          ecommerce,
        });
      }
    }, [ecommerce]);
  },
  useAddPaymentInfo() {
    return React.useCallback((paymentInfo: PaymentInfo | null, uuid?: string) => {
      if (paymentInfo && uuid) {
        window.dataLayer.push({
          event: 'add_payment_info',
          ecommerce: {
            ...paymentInfo,
            transaction_id: uuid,
          },
        });
      }
    }, []);
  },
  useAddPurchaseInfo() {
    return React.useCallback(async (
      purchaseInfo: PurchaseInfo | null,
      user_data?: Customer,
    ) => {
      if (purchaseInfo && user_data) {
        const [sha256_phone_number, sha256_email_address] = await Promise.all([
          callbacks.hashSha256(user_data.phoneNumber),
          callbacks.hashSha256(user_data.email),
        ]);

        window.dataLayer.push({
          event: 'purchase',
          user_data: {
            sha256_phone_number,
            sha256_email_address,
          },
          ecommerce: purchaseInfo,
        });
      }
    }, []);
  },
  useTrackRemoveFromCartV2() {
    return React.useCallback((ecommerce: DataLayerEcommerceGiftCardProductV3 | null) => {
      if (ecommerce) {
        window.dataLayer.push({
          event: 'remove_from_cart',
          ecommerce,
        });
      }
    }, []);
  },
  useTrackFormSubmitQuotation() {
    return React.useCallback(async (items: QuotationUserInfo | null) => {
      if (items) {
        const [user_hashed_email, user_hashed_phone_number] = await Promise.all([
          callbacks.hashSha256(items.user_email), callbacks.hashSha256(items.user_phone_number),
        ]);

        window.dataLayer.push({
          event: 'form_submit_quotation',
          user: {
            user_hashed_email,
            user_hashed_phone_number,
          },
        });
      }
    }, []);
  },
  useTrackSelectVariantShipping() {
    return React.useCallback( (item: GiftCardType) => {
      window.dataLayer.push({
        event: 'select_item_variant_shipping',
        selected_item_variant: item,
      });
    }, []);
  },
  useTrackSelectVariantPackage() {
    return React.useCallback( (title: string) => {
      window.dataLayer.push({
        event: 'select_item_variant_package',
        selected_item_variant: title,
      });
    }, []);
  },
  useTrackSelectVariantAmount() {
    return React.useCallback( (amount: number) => {
      window.dataLayer.push({
        event: 'select_item_variant_amount',
        selected_item_variant: amount,
      });
    }, []);
  },
  useClearDataLayer() {
    return React.useCallback(() => {
      window.dataLayer.push({
        ecommerce: null,
      });
    }, []);
  },
};
