import React from 'react';

import classNames from 'classnames';

import { TooltipContent, TooltipTrigger, Tooltip } from '@components/Tooltip/Tooltip';
import { Info as InfoIcon } from '@components/icons/Info/Info';

import styles from './PriceBreakdownLine.module.css';

export type PriceBreakdownItem = {
  bold?: boolean;
  id: string;
  info?: JSX.Element | string | null;
  isWrapperAttr?: boolean;
  label: React.ReactNode;
  price: string;
  priceValue?: number;
  secondary?: boolean;
};

export type Props = {
  className?: string;
  firstOfOrder?: boolean;
  textClassName?: string;
} & PriceBreakdownItem;

export const PriceBreakdownLine = React.memo((props: Props) => {
  const {
    label,
    bold,
    price,
    info = null,
    className,
    textClassName,
    firstOfOrder,
    secondary,
  } = props;

  return (
    <div className={classNames(
      styles.priceBreakdownLine,
      className,
      {
        [styles.firstOfOrder]: firstOfOrder,
      },
    )}>
      <div className={styles.labelWrapper}>
        <div
          className={classNames(styles.texts, textClassName, {
            [styles.bold]: bold,
            [styles.secondary]: secondary,
          })}
        >
          { label }
        </div>
        {info && (
          <Tooltip>
            <TooltipTrigger>
              <div className={styles.infoIconWrapper}>
                <InfoIcon />
              </div>
            </TooltipTrigger>
            <TooltipContent>
              { info }
            </TooltipContent>
          </Tooltip>
        )}
      </div>
      <div
        className={classNames(styles.texts, textClassName, {
          [styles.bold]: bold,
          [styles.secondary]: secondary,
        })}
      >
        { price }
      </div>
    </div>
  );
});

PriceBreakdownLine.displayName = 'PriceBreakdownLine';
