import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { apolloHooks } from '@nxte-nl/apollo-hooks';
import { callbackHooks } from '@nxte-nl/callback-hooks';

import { BasketModal } from '@components/BasketModal/BasketModal';
import { BasketModalContent } from '@components/BasketModalContent/BasketModalContent';
import {
  Addition,
  GiftCardBasketItem,
} from '@components/GiftcardBasketItem/GiftcardBasketItem';
import { GiftCardCheckoutMessages, GlobalMessages } from '@customTypes/Messages';
import { ApiGiftCardOrderResponse, BaseVariables } from '@customTypes/apiCompound';
import {
  Currency,
  GiftCardOrder,
  GiftCardType,
  MutationaddGiftCardPackageArgs,
  MutationaddGiftCardPackageItemArgs,
  MutationchangeGiftCardPackageQuantityArgs,
  MutationdeleteGiftCardPackageArgs,
  MutationdeleteOrderItemArgs,
  OrderFlow,
  Shipment,
  WineOrderItem,
} from '@customTypes/apiTypes';
import { dataLayerV2Hooks } from '@hooks/dataLayerV2Hooks/dataLayerV2Hooks';
import { dataMappingHooks } from '@hooks/dataMappingHooks/dataMappingHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';
import { addGiftCardPackage } from '@mutations/addGiftCardPackage.gql';
import { addGiftCardPackageItem } from '@mutations/addGiftCardPackageItem.gql';
import { changeGiftCardPackageQuantity } from '@mutations/changeGiftCardPackageQuantity.gql';
import { deleteGiftCardPackage } from '@mutations/deleteGiftCardPackage.gql';
import { deleteOrderItem } from '@mutations/deleteOrderItem.gql';

import { LayoutExtensionContainer } from '../LayoutExtensionContainer/LayoutExtensionContainer';

const messages = defineMessages({
  totalPrice: {
    id: 'src.components.BasketModalContainer.totalPrice',
    defaultMessage: 'Totaalprijs incl. btw',
  },
  shipmentText: {
    id: 'src.components.BasketModalContainer.shipmentText',
    defaultMessage: 'Verzendkosten',
  },
});

export const hooks = {
  useHandleDeletePackage(
    setOrder: (value: (ApiGiftCardOrderResponse | null)) => void,
  ) {
    const [mutate, { loading }] = apolloHooks
      .useMutation<ApiGiftCardOrderResponse, BaseVariables<MutationdeleteGiftCardPackageArgs>>(
        deleteGiftCardPackage, {
          onCompleted: data => setOrder(data),
          context: { orderFlow: OrderFlow.giftcard_order },
        },
      );
    const onDeleteGiftcardPackage = React.useCallback((packageId: string) => {
      mutate({ variables: { packageID: packageId } });
    }, [mutate]);
    const setDisabled = messageHooks.useNextWithValue(
      GiftCardCheckoutMessages.GiftcardBasketModalDisabled, loading,
    );
    callbackHooks.useEffectCallback(setDisabled);
    return { onDeleteGiftcardPackage };
  },
  useHandleUnDeletePackage(
    setOrder: (value: (ApiGiftCardOrderResponse | null)) => void,
  ) {
    const [mutate, { loading }] = apolloHooks
      .useMutation<ApiGiftCardOrderResponse, BaseVariables<MutationaddGiftCardPackageArgs>>(
        addGiftCardPackage, {
          onCompleted: data => setOrder(data),
          context: { orderFlow: OrderFlow.giftcard_order },
        },
      );
    const onUnDeleteGiftcardPackage =
      React.useCallback((giftCardBasketItem: GiftCardBasketItem) => {
        mutate({
          variables: {
            giftCardPackageData: {
              quantity: giftCardBasketItem.quantity,
              giftCardSKU: giftCardBasketItem.giftCardSKU,
              giftPackingSKU: giftCardBasketItem.giftPackingSKU,
              wrapperSKU: giftCardBasketItem.wrapperSKU,
              personalMessageSKU: giftCardBasketItem.personalMessageSKU,
              personalMessageText: giftCardBasketItem.personalMessageText,
              extraProductsSKUs: giftCardBasketItem.extraProductsSKUs,
            },
            packageID: null,
          },
        });
      }, [mutate]);
    const setDisabled = messageHooks.useNextWithValue(
      GiftCardCheckoutMessages.GiftcardBasketModalDisabled, loading,
    );
    callbackHooks.useEffectCallback(setDisabled);
    return { onUnDeleteGiftcardPackage };
  },
  useOrderState() {
    const order = messageHooks.useSubjectState(GiftCardCheckoutMessages.GiftcardBasket);
    const setOrder = messageHooks.useNext(GiftCardCheckoutMessages.GiftcardBasket);
    return { order, setOrder };
  },
  useChangePackageHandler(
    packageId: string,
    order: ApiGiftCardOrderResponse | null,
    setPackageId: (packageId: string) => void,
    setOrder: (value: (ApiGiftCardOrderResponse | null)) => void,
  ) {
    const trackAddToCartV2 = dataLayerV2Hooks.useTrackAddToCartV2();
    const trackRemoveFromCartV2 = dataLayerV2Hooks.useTrackRemoveFromCartV2();
    const clearDataLayer = dataLayerV2Hooks.useClearDataLayer();
    const [mutate, { loading }] = apolloHooks
      .useMutation<ApiGiftCardOrderResponse,
        BaseVariables<MutationchangeGiftCardPackageQuantityArgs>>(
          changeGiftCardPackageQuantity, {
            onCompleted: (data) => {
              const oldPackages = dataMappingHooks.useFlatPackagesFromOrder(order);
              const { giftCardOrderItem: oldGiftCardOrderItem } =
                dataMappingHooks.useFindGiftCardOrderItemById(packageId, oldPackages);
              const newPackages = dataMappingHooks.useFlatPackagesFromOrder(data);
              const {
                giftCardOrderItem: newGiftCardOrderItem,
                packageItem: newPackageItem,
              } = dataMappingHooks.useFindGiftCardOrderItemById(packageId, newPackages);
              const { mappedItemsV2: items } =
                dataMappingHooks.useMapAllShipmentsV2(newPackageItem ? [newPackageItem] : [], true);
              if (oldGiftCardOrderItem && newGiftCardOrderItem) {
                const value = items?.reduce((prev, curr) =>
                  prev + Number(curr.price), 0) ?? 0;
                if (newGiftCardOrderItem.qty > oldGiftCardOrderItem.qty) {
                  const tax = data?.data.order?.orderTaxes.reduce((prev, cur) =>
                    prev + cur.taxAmount.value, 0) ?? 0;
                  clearDataLayer();
                  items && trackAddToCartV2({
                    currency: newGiftCardOrderItem.unitPrice.currency,
                    value: value - (tax / newGiftCardOrderItem.qty),
                    items,
                  });
                } else if (newGiftCardOrderItem.qty < oldGiftCardOrderItem.qty) {
                  const tax = data?.data.order?.orderTaxes.reduce((prev, cur) =>
                    prev + cur.taxAmount.value, 0) ?? 0;
                  clearDataLayer();
                  items && trackRemoveFromCartV2({
                    currency: newGiftCardOrderItem.unitPrice.currency,
                    value: value - (tax / newGiftCardOrderItem.qty),
                    items,
                  });
                }
              }
              setOrder(data);
            },
            context: { orderFlow: OrderFlow.giftcard_order },
          },
        );
    const onGiftcardPackageChange =
      React.useCallback((giftCardBasketItem: GiftCardBasketItem) => {
        setPackageId(giftCardBasketItem.id);
        mutate({
          variables: {
            packageID: giftCardBasketItem.id,
            quantity: giftCardBasketItem.quantity,
          },
        });
      }, [mutate, setPackageId]);
    const setDisabled = messageHooks.useNextWithValue(
      GiftCardCheckoutMessages.GiftcardBasketModalDisabled, loading,
    );
    callbackHooks.useEffectCallback(setDisabled);
    return { onGiftcardPackageChange };
  },
  useShipmentToBasketItemMapping(shipments?: Shipment[] | null) {
    return React.useMemo(() => {
      const basketItems =
        shipments?.reduce<GiftCardBasketItem[]>((basketItems, s) => {
          const items = s.packages?.reduce<GiftCardBasketItem[]>((basketItems, p) => {
            const packageTotalPrices = p.items?.map((item) => item.totalPrice) || [];
            const packageExtrasTotalPrices = p.extraItems?.map((item) => item.totalPrice) || [];
            const allThePackageTotalPrices = packageTotalPrices.concat(packageExtrasTotalPrices);
            const totalPrice = allThePackageTotalPrices?.reduce((acc, price) => {
              return { ...acc, value: acc.value + price.value, currency: price.currency };
            }, { value: 0, currency: Currency.EUR });

            let basketItem = null;
            if (p.items) {
              basketItem = p.items.reduce<GiftCardBasketItem>((basketItem, item) => {
                if (item.__typename === 'GiftCardOrderItem') {
                  return {
                    ...basketItem,
                    id: p.id,
                    title: item.title,
                    giftCardType: item.productVariation.giftCardType,
                    quantity: item.qty,
                    price: item.totalPrice,
                    giftCardSKU: item.productVariation.sku,
                    type: item.__typename,
                  };
                } else if (item.__typename === 'GiftPackingOrderItem') {
                  return {
                    ...basketItem,
                    giftPackingSKU: item.productVariation.sku,
                    additions: [
                      ...basketItem.additions,
                      {
                        sku: item.productVariation.sku,
                        itemID: item.id,
                        id: item.productVariation.id,
                        title: item.productVariation.product.title,
                        price: item.totalPrice,
                        type: item.__typename,
                      },
                    ],
                  };
                } else if (item.__typename === 'WrapperOrderItem') {
                  return {
                    ...basketItem,
                    wrapperSKU: item.productVariation.sku,
                    additions: [
                      ...basketItem.additions,
                      {
                        sku: item.productVariation.sku,
                        itemID: item.id,
                        id: item.productVariation.id,
                        title: item.productVariation.product.title,
                        price: item.totalPrice,
                        type: item.__typename,
                      },
                    ],
                  };
                } else if (item.__typename === 'PersonalMessageOrderItem') {
                  return {
                    ...basketItem,
                    personalMessageText: item.messageText,
                    personalMessageSKU: item.productVariation.sku,
                    additions: [
                      ...basketItem.additions,
                      {
                        sku: item.productVariation.sku,
                        personalMessageText: item.messageText,
                        itemID: item.id,
                        id: item.productVariation.id,
                        title: item.productVariation.product.title,
                        price: item.totalPrice,
                        type: item.__typename,
                      },
                    ],
                  };
                }

                return basketItem;
              }, {
                giftCardType: GiftCardType.PHYSICAL,
                giftPackingSKU: null,
                wrapperSKU: null,
                personalMessageText: null,
                personalMessageSKU: null,
                extraProductsSKUs: null,
                additions: [] as Addition[],
                packagePrice: {
                  ...totalPrice,
                  value: totalPrice.value ? Math.round(totalPrice.value * 100) / 100 : 0,
                },
              } as GiftCardBasketItem);
            }

            if (basketItem && p.extraItems?.length) {
              const extraProductsSKUs =
                p.extraItems.map((ex: WineOrderItem) => ex.productVariation.sku);
              const extraAdditions = p.extraItems.map((ex: WineOrderItem) => ({
                sku: ex.productVariation.sku,
                itemID: ex.id,
                id: ex.id,
                title: ex.title,
                price: ex.totalPrice,
                type: ex.__typename || '',
              }));
              basketItem = {
                ...basketItem,
                extraProductsSKUs,
                additions: [
                  ...basketItem.additions,
                  ...extraAdditions,
                ],
              };
            }

            return basketItem ? [...basketItems, basketItem] : basketItems;
          }, []);

          return items ? [...basketItems, ...items] : basketItems;
        }, []) ?? null;

      return basketItems ?? null;
    }, [shipments]);
  },
  useCloseWithRedirect(onCLose: () => void, giftcardDetailsRedirect: () => string) {
    return React.useCallback(() => {
      onCLose();
      window.location.href = giftcardDetailsRedirect();
    }, [onCLose, giftcardDetailsRedirect]);
  },
  useHandleDeleteOrderItem(setOrder: (value: (ApiGiftCardOrderResponse | null)) => void) {
    const [mutate, { loading }] = apolloHooks
      .useMutation<ApiGiftCardOrderResponse, BaseVariables<MutationdeleteOrderItemArgs>>(
        deleteOrderItem, {
          onCompleted: data => setOrder(data),
          context: { orderFlow: OrderFlow.giftcard_order },
        },
      );

    const onDeleteOrderItem = React.useCallback((itemID: string) => {
      mutate({ variables: { itemID: itemID } });
    }, [mutate]);

    const setDisabled = messageHooks.useNextWithValue(
      GiftCardCheckoutMessages.GiftcardBasketModalDisabled, loading,
    );

    callbackHooks.useEffectCallback(setDisabled);

    return { onDeleteOrderItem };
  },
  useHandleUnDeleteOrderItem(setOrder: (value: (ApiGiftCardOrderResponse | null)) => void) {
    const [mutate, { loading }] = apolloHooks
      .useMutation<ApiGiftCardOrderResponse, BaseVariables<MutationaddGiftCardPackageItemArgs>>(
        addGiftCardPackageItem, {
          onCompleted: data => setOrder(data),
          context: { orderFlow: OrderFlow.giftcard_order },
        },
      );

    const onUnDeleteOrderItem =
      React.useCallback((
        itemSKU: string,
        personalMessageText: string | null,
        packageID: string,
      ) => {
        mutate({
          variables: {
            itemSKU: itemSKU,
            personalMessageText: personalMessageText,
            packageID: packageID,
          },
        });
      }, [mutate]);

    const setDisabled = messageHooks.useNextWithValue(
      GiftCardCheckoutMessages.GiftcardBasketModalDisabled, loading,
    );

    callbackHooks.useEffectCallback(setDisabled);

    return { onUnDeleteOrderItem };
  },
  useGiftCardOrderOverviewMapping(order?: GiftCardOrder | null) {
    const intl = useIntl();

    return React.useMemo(() => {
      const shipmentCost = order?.shipments?.reduce((sum, s) =>
        s.shipmentCost ? s.shipmentCost.value + sum : sum, 0);
      const allTaxesAmount = order?.orderTaxes.reduce((sum, tax) =>
        tax.taxAmount ? tax.taxAmount.value + sum : sum, 0);
      const revenue = (order?.grandTotal?.value || 0) - (shipmentCost || 0) - (allTaxesAmount || 0);

      return {
        orderTaxes: order?.orderTaxes
          ? order.orderTaxes.map((tax) => ({
            label: tax.taxLabel,
            price: intl.formatNumber(
              tax.taxAmount.value,
              { style: 'currency', currency: tax.taxAmount.currency },
            ),
          }))
          : null,
        grandTotal: order?.grandTotal ? {
          label: intl.formatMessage(messages.totalPrice),
          price: intl.formatNumber(
            order.grandTotal.value,
            { style: 'currency', currency: order.grandTotal.currency },
          ),
        } : null,
        shipmentCost: shipmentCost && order?.shipments?.length ? {
          label: intl.formatMessage(messages.shipmentText),
          price: intl.formatNumber(
            shipmentCost,
            { style: 'currency', currency: order?.shipments[0].shipmentCost.currency },
          ),
        } : null,
        revenue,
      };
    }, [intl, order]);
  },
};

export type Props = {
  continueLoading: boolean;
  expanded: boolean;
  onContinueButtonClick?: () => void;
  withRedirect?: boolean;
};

export const BasketModalContainer = React.memo((props: Props) => {
  const pathname = navigationHooks.usePathname();
  const [packageId, setPackageId] = React.useState<string>('');
  const { order, setOrder } = hooks.useOrderState();
  const hideModal = messageHooks.useNextWithValue(GlobalMessages.ModalOpen, null);

  const { onDeleteGiftcardPackage } = hooks.useHandleDeletePackage(setOrder);
  const { onUnDeleteGiftcardPackage } = hooks.useHandleUnDeletePackage(setOrder);
  const { onGiftcardPackageChange } =
    hooks.useChangePackageHandler(packageId, order, setPackageId, setOrder);
  const { onDeleteOrderItem } = hooks.useHandleDeleteOrderItem(setOrder);
  const { onUnDeleteOrderItem } = hooks.useHandleUnDeleteOrderItem(setOrder);

  const items = hooks.useShipmentToBasketItemMapping(order?.data.order?.shipments);
  const { orderTaxes, grandTotal, shipmentCost, revenue } =
    hooks.useGiftCardOrderOverviewMapping(order?.data.order);

  const disabledState =
    messageHooks.useSubjectState(GiftCardCheckoutMessages.GiftcardBasketModalDisabled);
  const giftcardDetailsRedirect = navigationHooks.useGiftCardRedirect();
  const handleRedirect = hooks.useCloseWithRedirect(hideModal, giftcardDetailsRedirect);

  const allPackages = dataMappingHooks.useFlatPackagesFromOrder(order);
  const { mappedItemsV2 } = dataMappingHooks.useMapAllShipmentsV2(allPackages);
  const ecommerce = React.useMemo(() => {
    const tax = order?.data.order?.orderTaxes.reduce((prev, cur) =>
      prev + cur.taxAmount.value, 0) ?? 0;
    const value = mappedItemsV2?.reduce((prev, curr) =>
      prev + (Number(curr.price) * curr.quantity), 0) ?? 0;
    const unitPrice = allPackages.length && allPackages[0].items?.[0]?.unitPrice;
    return unitPrice && mappedItemsV2 ? {
      currency: unitPrice.currency,
      value: value - tax,
      items: mappedItemsV2 || [],
    } : null;
  }, [order?.data.order?.orderTaxes, mappedItemsV2, allPackages]);

  const { continueLoading, onContinueButtonClick, withRedirect, expanded } = props;
  const isCheckoutWithoutItems = pathname.includes('buy/pay') && !items?.length;

  return (
    <LayoutExtensionContainer>
      {({ layoutExtension }) => (
        <BasketModal
          onClose={isCheckoutWithoutItems ? handleRedirect : hideModal}
          expanded={expanded}
          layoutExtension={layoutExtension}
          loadingModal={disabledState}
          ecommerce={ecommerce}
          contentSection={
            <BasketModalContent
              onContinueButtonClick={onContinueButtonClick}
              continueLoading={continueLoading}
              onSecondaryButtonClick={withRedirect ? handleRedirect : hideModal}
              disabled={disabledState}
              onGiftcardPackageChange={onGiftcardPackageChange}
              onDeleteOrderItem={onDeleteOrderItem}
              onUnDeleteOrderItem={onUnDeleteOrderItem}
              orderTaxes={orderTaxes}
              grandTotal={grandTotal}
              shipmentCost={shipmentCost}
              items={items}
              onDeleteGiftcardPackage={onDeleteGiftcardPackage}
              onUnDeleteGiftcardPackage={onUnDeleteGiftcardPackage}
              layout={layoutExtension ?? undefined}
              mappedItems={mappedItemsV2}
              revenue={revenue}
              currency={allPackages.length
                ? allPackages?.[0].items?.[0].totalPrice?.currency : null
              }
            />
          }
        />
      )}
    </LayoutExtensionContainer>
  );
});

BasketModalContainer.displayName = 'BasketModalContainer';
