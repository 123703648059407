import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import { GiftcardBasketItemContainer }
  from '@containers/GiftcardBasketItemContainer/GiftcardBasketItemContainer';

import { GiftCardBasketItem } from '../GiftcardBasketItem/GiftcardBasketItem';
import { UndoBlock } from '../UndoBlock/UndoBlock';
import styles from './GiftcardBasketItemWrap.module.css';

const messages = defineMessages({
  undoText: {
    id: 'src.components.GiftcardBasketItemWrap.undoText',
    defaultMessage: '{title} is verwijderd.',
  },
});

export const hooks = {
  useHandleUnDeletePackage(
    item: GiftCardBasketItem,
    onUnDeletePackage: (giftCardBasketItem: GiftCardBasketItem) => void,
  ) {
    return React.useCallback(() => {
      onUnDeletePackage(item);
    }, [item, onUnDeletePackage]);
  },
};

export type Props = {
  data: GiftCardBasketItem;
  disabled: boolean;
  onDeleteOrderItem: (itemID: string) => void;
  onDeletePackage: (giftCardBasketItem: GiftCardBasketItem) => void;
  onGiftcardPackageChange: (giftCardBasketItem: GiftCardBasketItem) => void;
  onUnDeleteOrderItem:
    (itemSKU: string, personalMessageText: string | null, packageID: string) => void;
  onUnDeletePackage: (giftCardBasketItem: GiftCardBasketItem) => void;
};

export const GiftcardBasketItemWrap = React.memo((props: Props) => {
  const {
    data,
    disabled,
    onDeletePackage,
    onUnDeletePackage,
    onGiftcardPackageChange,
    onDeleteOrderItem,
    onUnDeleteOrderItem,
  } = props;

  const handleUnDeleteGiftcardPackage =
    hooks.useHandleUnDeletePackage(data, onUnDeletePackage);

  return (
    <>
      <div className={classNames({
        [styles.hidden]: data.status === 'holding' || data.status === 'deleting',
      })}>
        <UndoBlock
          className={styles.undoBlock}
          text={
            <FormattedMessage
              values={{ title: data.title }}
              {...messages.undoText}
            />
          }
          onUndo={handleUnDeleteGiftcardPackage}
          disabled={disabled}
        />
      </div>
      <div className={classNames({
        [styles.hidden]: data.status === 'deleted' || data.status === 'restoring',
      })}>
        <GiftcardBasketItemContainer
          packageItem={data}
          disabled={disabled}
          onDeletePackage={onDeletePackage}
          onGiftcardPackageChange={onGiftcardPackageChange}
          onDeleteOrderItem={onDeleteOrderItem}
          onUnDeleteOrderItem={onUnDeleteOrderItem}
        />
      </div>
    </>
  );
});

GiftcardBasketItemWrap.displayName = 'GiftcardBasketItemWrap';
