import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import { callbackHooks } from '@nxte-nl/callback-hooks';

import { LayoutExtension } from '@customTypes/ResponsiveState';
import { dataLayerV2Hooks } from '@hooks/dataLayerV2Hooks/dataLayerV2Hooks';

import { Button, ButtonFit } from '../Button/Button';
import styles from './BasketModalButtons.module.css';

export type Props = {
  continueLoading: boolean;
  layout?: LayoutExtension;
  onContinueButtonClick: () => void;
  onSecondaryButtonClick: () => void;
  proceedToCheckout: boolean;
};

const messages = defineMessages({
  addMore: {
    id: 'src.components.BasketModalButtons.addMore',
    defaultMessage: 'Nog een toevoegen',
  },
  settle: {
    id: 'src.components.BasketModalButtons.settle',
    defaultMessage: 'Afrekenen',
  },
});

export const BasketModalButtons = React.memo((props: Props) => {
  const {
    continueLoading,
    onContinueButtonClick,
    onSecondaryButtonClick,
    layout,
    proceedToCheckout,
  } = props;

  const intl = useIntl();
  const basketBuyRedirectBtnId = 'basket-buy-redirect-button';
  const trackSelectItem = dataLayerV2Hooks.useTrackSelectItem(
    basketBuyRedirectBtnId,
    intl.formatMessage(messages.addMore),
  );

  const onSecondaryBtnClick =
    callbackHooks.useWrapCallbacks([trackSelectItem, onSecondaryButtonClick]);

  return (
    <div className={styles.basketModalButtons}>
      <Button
        {...(layout === LayoutExtension.Mobile ? { fit: ButtonFit.Fill } : {})}
        id={basketBuyRedirectBtnId}
        color='blue'
        intent='secondaryV2'
        size='medium'
        className={styles.addMoreButton}
        onClick={onSecondaryBtnClick}
      >
        <FormattedMessage {...messages.addMore} />
      </Button>
      {proceedToCheckout
        ? <Button
          {...(layout === LayoutExtension.Mobile ? { fit: ButtonFit.Fill } : {})}
          intent='primaryV2'
          size='medium'
          loading={continueLoading}
          onClick={onContinueButtonClick}
        >
          <FormattedMessage {...messages.settle} />
        </Button>
        : null}
    </div>
  );
});

BasketModalButtons.displayName = 'BasketModalButtons';
