import React from 'react';

import classNames from 'classnames';

import { StaticImage } from '@nxte-nl/static-image';

import giftcardLogo from '@images/giftcard_logo.jpg';

import styles from './GiftcardInfoLine.module.css';

export type Props = {
  bold?: boolean;
  classname?: string;
  quantity: number;
  title: string | JSX.Element;
  titleClassname?: string;
};

export const GiftcardInfoLine = React.memo((props: Props) => {
  const {
    title,
    quantity,
    classname,
    bold = false,
    titleClassname,
  } = props;

  return (
    <div className={classNames(styles.giftcardInfoLine, classname, {
      [styles.bold]: bold,
    })}>
      <StaticImage
        src={giftcardLogo}
        className={styles.giftcardLogo}
        alt={''}
        width={42}
        height={'auto'}
      />
      <span className={styles.quantity}>{quantity}x</span>
      <span className={titleClassname}>
        {title}
      </span>
    </div>
  );
});

GiftcardInfoLine.displayName = 'GiftcardInfoLine';
