import React from 'react';

import useResizeObserver from '@react-hook/resize-observer';
import classNames from 'classnames';

import { stateHooks } from '@nxte-nl/state-hooks';

import { ChevronDown } from '../icons/ChevronDown/ChevronDown';
import styles from './AnimatedShowMore.module.css';

export enum LayoutFit {
  OneColumn = 'oneColumn',
  TwoColumns = 'twoColumns',
}

export type AdditionalProps = {
  chevronIconClass?: string;
  defaultCount?: number;
  expandLinkContent: {
    less?: JSX.Element | string;
    more: JSX.Element | string;
  };
  filtersWrapperClass?: string;
  hideBtnAfterExpand?: boolean;
  layoutTemplate?: LayoutFit;
  mainContentWrapperClass?: string;
  toggleButtonClass?: string;
  withoutArrows?: boolean;
};

export type Props = AdditionalProps & {
  children: JSX.Element[];
  initialExpandedState?: boolean;
  speed?: number;
};

export type ClientHeightEntry = {
  target: {
    clientHeight: number;
  };
};

export const hooks = {
  useContentHeight() {
    const [height, setHeight] = React.useState<null | number>(null);
    return {
      height,
      setHeight: React.useCallback((e: ClientHeightEntry) => setHeight(e.target.clientHeight), [
        setHeight,
      ]),
    };
  },
  useCollapsedStyle(speed: number) {
    const ref = React.useRef<HTMLDivElement>(null);
    const { height, setHeight } = hooks.useContentHeight();

    useResizeObserver(ref, setHeight);

    const styleObject = React.useMemo(() => {
      return {
        height: height !== null ? `${height}px` : '0px',
        transition: `height ${speed}ms linear`,
      };
    }, [height, speed]);

    return { ref, styleObject };
  },
  useChildrenToRender(children: JSX.Element[], defaultCount: number) {
    const alwaysRenderedItems: JSX.Element[] = [];
    let itemsToToggle: JSX.Element[] = [];

    const alwaysRenderedQuantity = defaultCount;

    if (alwaysRenderedQuantity === 0) {
      itemsToToggle = children;
    } else {
      children.forEach((item, index) => {
        if (index < alwaysRenderedQuantity) {
          alwaysRenderedItems.push(item);
        } else {
          itemsToToggle.push(item);
        }
      });
    }

    const countMoreThanNeededForBtnVisibility = alwaysRenderedQuantity < children.length;

    return {
      alwaysRenderedItems,
      itemsToToggle,
      countMoreThanNeededForBtnVisibility,
    };
  },
};

export const AnimatedShowMore = React.memo(
  ({
    speed = 500,
    children,
    initialExpandedState = false,
    layoutTemplate = LayoutFit.OneColumn,
    defaultCount = 4,
    expandLinkContent,
    hideBtnAfterExpand = false,
    chevronIconClass,
    toggleButtonClass,
    mainContentWrapperClass,
    filtersWrapperClass,
    withoutArrows,
  }: Props) => {
    const {
      alwaysRenderedItems,
      itemsToToggle,
      countMoreThanNeededForBtnVisibility,
    } = hooks.useChildrenToRender(children, defaultCount);

    const { value: isExpanded, toggle: handleToggle } = stateHooks.useBooleanState({
      initialState: initialExpandedState,
    });
    const { ref, styleObject } = hooks.useCollapsedStyle(speed);

    const expandLinkContentLess = expandLinkContent?.less ?? expandLinkContent.more;

    const buttonContent = isExpanded ? expandLinkContentLess : expandLinkContent.more;

    const showButton =
      (hideBtnAfterExpand && isExpanded) || !countMoreThanNeededForBtnVisibility ? false : true;

    return (
      <>
        <div className={classNames(styles.mainContentWrapper, mainContentWrapperClass)}>
          <div
            style={styleObject}
            className={classNames(styles.filtersWrapper, filtersWrapperClass, styles.mainContent, {
              [styles.oneColumn]: layoutTemplate === LayoutFit.OneColumn,
              [styles.twoColumns]: layoutTemplate === LayoutFit.TwoColumns,
            })}
          >
            {children}
          </div>

          {/* Hidden section, rendered for the purpose to count visible filter's section height*/}
          <div className={styles.mainContentInvisible} aria-hidden='true'>
            <div
              className={classNames(styles.filtersWrapper, filtersWrapperClass, {
                [styles.oneColumn]: layoutTemplate === LayoutFit.OneColumn,
                [styles.twoColumns]: layoutTemplate === LayoutFit.TwoColumns,
              })}
              ref={ref}
            >
              {alwaysRenderedItems}
              {isExpanded && itemsToToggle}
            </div>
          </div>
        </div>

        {showButton && (
          <div
            className={classNames(styles.toggleButton, toggleButtonClass)}
            onClick={handleToggle}
          >
            {buttonContent}
            {withoutArrows
              ? null
              : <ChevronDown
                width={8}
                height={8}
                className={classNames(
                  styles.chevronIcon,
                  chevronIconClass,
                  isExpanded ? styles.isOpen : null,
                )}
              />
            }
          </div>
        )}
      </>
    );
  },
);

AnimatedShowMore.displayName = 'AnimatedShowMore';
