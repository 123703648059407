import React from 'react';

import { GiftCardBasketItem } from '@components/GiftcardBasketItem/GiftcardBasketItem';
import { GiftcardBasketItemWrap }
  from '@components/GiftcardBasketItemWrap/GiftcardBasketItemWrap';
import { GiftCardCheckoutMessages } from '@customTypes/Messages';
import { dataLayerV2Hooks } from '@hooks/dataLayerV2Hooks/dataLayerV2Hooks';
import { dataMappingHooks } from '@hooks/dataMappingHooks/dataMappingHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';

export const hooks = {
  useUnDeletePackageDataLayer(restoredIdRef: React.MutableRefObject<string | null>) {
    if (!restoredIdRef.current) {
      return;
    }

    const basket = messageHooks.useSubjectState(GiftCardCheckoutMessages.GiftcardBasket);
    const trackAddToCartV2 = dataLayerV2Hooks.useTrackAddToCartV2();
    const clearDataLayer = dataLayerV2Hooks.useClearDataLayer();
    const packages = dataMappingHooks.useFlatPackagesFromOrder(basket);
    const { packageItem, giftCardOrderItem } = dataMappingHooks
      .useFindGiftCardOrderItemById(restoredIdRef.current, packages);
    const { mappedItemsV2: items } =
      dataMappingHooks.useMapAllShipmentsV2(packageItem ? [packageItem] : []);
    if (giftCardOrderItem && items) {
      const tax = basket?.data.order?.orderTaxes.reduce((prev, cur) =>
        prev + cur.taxAmount.value, 0) ?? 0;
      const value = items?.reduce((prev, curr) =>
        prev + (Number(curr.price) * curr.quantity), 0) ?? 0;
      clearDataLayer();
      trackAddToCartV2({
        currency: giftCardOrderItem.unitPrice.currency,
        value: value - tax,
        items,
      });
    }

    restoredIdRef.current = null;
  },
};

export type Props = {
  data: GiftCardBasketItem;
  disabled: boolean;
  onDeleteOrderItem: (itemID: string) => void;
  onDeletePackage: (giftCardBasketItem: GiftCardBasketItem) => void;
  onGiftcardPackageChange: (giftCardBasketItem: GiftCardBasketItem) => void;
  onUnDeleteOrderItem:
  (itemSKU: string, personalMessageText: string | null, packageID: string) => void;
  onUnDeletePackage: (giftCardBasketItem: GiftCardBasketItem) => void;
  restoredIdRef: React.MutableRefObject<string | null>;
};

export const GiftcardBasketItemWrapContainer = (props: Props) => {
  const {
    restoredIdRef,
  } = props;

  hooks.useUnDeletePackageDataLayer(
    restoredIdRef,
  );

  return (
    <GiftcardBasketItemWrap
      {...props}
    />
  );
};

GiftcardBasketItemWrapContainer.displayName = 'GiftcardBasketItemWrapContainer';
