import React from 'react';

import { GiftcardBasketItem, GiftCardBasketItem }
  from '@components/GiftcardBasketItem/GiftcardBasketItem';
import { GiftCardCheckoutMessages } from '@customTypes/Messages';
import { dataLayerV2Hooks } from '@hooks/dataLayerV2Hooks/dataLayerV2Hooks';
import { dataMappingHooks } from '@hooks/dataMappingHooks/dataMappingHooks';
import { messageHooks } from '@hooks/messageHooks/messageHooks';

export const hooks = {
  useHandleDeletePackage(
    item: GiftCardBasketItem,
    onDeletePackage: (giftCardBasketItem: GiftCardBasketItem) => void,
  ) {
    const basket = messageHooks.useSubjectState(GiftCardCheckoutMessages.GiftcardBasket);
    const trackRemoveFromCartV2 = dataLayerV2Hooks.useTrackRemoveFromCartV2();
    const clearDataLayer = dataLayerV2Hooks.useClearDataLayer();
    const packages = dataMappingHooks.useFlatPackagesFromOrder(basket);
    const { packageItem, giftCardOrderItem } = dataMappingHooks
      .useFindGiftCardOrderItemById(item.id, packages);
    const { mappedItemsV2: items } =
      dataMappingHooks.useMapAllShipmentsV2(packageItem ? [packageItem] : []);
    const tax = basket?.data.order?.orderTaxes.reduce((prev, cur) =>
      prev + cur.taxAmount.value, 0) ?? 0;
    const value = items?.reduce((prev, curr) =>
      prev + (Number(curr.price) * curr.quantity), 0) ?? 0;

    const handleDeleteGiftcardPackage = React.useCallback(() => {
      onDeletePackage(item);
      clearDataLayer();
      giftCardOrderItem && items && trackRemoveFromCartV2({
        currency: giftCardOrderItem.unitPrice.currency,
        value: value - tax,
        items,
      });
    }, [
      value,
      tax,
      item,
      giftCardOrderItem,
      items,
      onDeletePackage,
      trackRemoveFromCartV2,
      clearDataLayer,
    ]);

    return {
      handleDeleteGiftcardPackage,
    };
  },
};

export type Props = {
  disabled: boolean;
  onDeleteOrderItem: (itemID: string) => void;
  onDeletePackage: (giftCardBasketItem: GiftCardBasketItem) => void;
  onGiftcardPackageChange: (giftCardBasketItem: GiftCardBasketItem) => void;
  onUnDeleteOrderItem: (id: string, itemSKU: string, personalMessageText: string | null) => void;
  packageItem: GiftCardBasketItem;
};

export const GiftcardBasketItemContainer = (props: Props) => {
  const {
    packageItem,
    onDeletePackage,
  } = props;

  const { handleDeleteGiftcardPackage } =
    hooks.useHandleDeletePackage(packageItem, onDeletePackage);

  return (
    <GiftcardBasketItem
      {...props}
      handleDeleteGiftcardPackage={handleDeleteGiftcardPackage}
    />
  );
};

GiftcardBasketItemContainer.displayName = 'GiftcardBasketItemContainer';
