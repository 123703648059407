import React from 'react';

import classnames from 'classnames';

import styles from './CircleButton.module.css';

export type Props = {
  children: JSX.Element | string;
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler;
  size?: 'small' | 'medium';
};

export const CircleButton = React.memo((props: Props) => {
  const { children, className, size = 'medium', onClick, disabled } = props;
  return (
    <div
      className={classnames(styles.circleButton, className, {
        [styles.disabled]: disabled,
      }, styles[size])}
      onClick={onClick}
    >
      {children}
    </div>
  );
});

CircleButton.displayName = 'CircleButton';
