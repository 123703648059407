import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { Modal } from '@components/Modal/Modal';
import {
  DataLayerEcommerceGiftCardProductV3,
} from '@customTypes/DataLayer';
import { LayoutExtension } from '@customTypes/ResponsiveState';
import { dataLayerV2Hooks } from '@hooks/dataLayerV2Hooks/dataLayerV2Hooks';

import { Typography } from '../Typography/Typography';
import styles from './BasketModal.module.css';

export type Props = {
  contentSection: React.ReactNode;
  ecommerce: DataLayerEcommerceGiftCardProductV3 | null;
  expanded: boolean;
  layoutExtension: LayoutExtension | null;
  loadingModal: boolean;
  onClose: () => void;
};

const messages = defineMessages({
  title: {
    id: 'src.components.BasketModal.title',
    defaultMessage: 'Je bestelling',
  },
});

export const hooks = {
  useBasketDataLayer(
    expanded: boolean,
    ecommerce: DataLayerEcommerceGiftCardProductV3 | null,
  ) {
    const prevExpandedRef = React.useRef(false);

    const clearDataLayer = dataLayerV2Hooks.useClearDataLayer();
    const trackViewCart = dataLayerV2Hooks.useViewCart();

    React.useEffect(() => {
      if (!expanded) prevExpandedRef.current = false;
      else if (!prevExpandedRef.current && ecommerce) {
        prevExpandedRef.current = true;

        clearDataLayer();
        trackViewCart(ecommerce);
      }
    }, [clearDataLayer, expanded, ecommerce, trackViewCart]);
  },
};

export const BasketModal = React.memo((props: Props) => {
  const {
    expanded,
    layoutExtension,
    loadingModal,
    onClose,
    contentSection,
    ecommerce,
  } = props;

  hooks.useBasketDataLayer(expanded, ecommerce);

  return (
    <Modal
      isOpen={expanded}
      onClose={onClose}
      layoutExtension={layoutExtension}
      loading={loadingModal}
      modalClassName={styles.modal}
      headerClassName={styles.modalHeader}
      title={
        <Typography className={styles.modalHeaderTitle} variant='h3'>
          <FormattedMessage {...messages.title} />
        </Typography>
      }>
      <div className={styles.modalContent}>
        {contentSection}
      </div>
    </Modal>
  );
});

BasketModal.displayName = 'BasketModal';
