import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import classNames from 'classnames';

import { LanguageCodeMapped } from '@customTypes/common';
import {
  languageResourcesHooks,
} from '@hooks/languageResourcesHooks/languageResourcesHooks';
import { navigationHooks } from '@hooks/navigationHooks/navigationHooks';

import { PriceBreakdownLine } from '../PriceBreakdownLine/PriceBreakdownLine';
import styles from './GiftcardOrderTotals.module.css';

export type TotalsItem = {
  label: string;
  price: string;
};

export type Props = {
  grandTotal: TotalsItem | null;
  orderTaxes: TotalsItem[] | null;
  shipmentCost: TotalsItem | null;
};

const messages = defineMessages({
  shippingPopupInfo: {
    id: 'src.components.GiftcardOrderTotals.shippingPopupInfo',
    defaultMessage: 'Ihre Bestellung wird am selben Arbeitstag bearbeitet und mit Sendungsverfolgung versandt. Innerhalb von 48 Stunden erhalten Sie eine E-Mail mit der Sendungsverfolgungsnummer und den Versandinformationen.',
  },
});

export const GiftcardOrderTotals = React.memo((props: Props) => {
  const { shipmentCost, grandTotal, orderTaxes } = props;
  const language = navigationHooks.useLanguage();
  const neededLanguages = [
    LanguageCodeMapped.DeDe,
    LanguageCodeMapped.EnDe,
  ];
  const displayShippingInfoPopup = languageResourcesHooks
    .useNeededLanguages(language, neededLanguages);

  return (
    <div>
      {shipmentCost && (
        <PriceBreakdownLine
          id='shipmentCost'
          label={shipmentCost.label}
          price={shipmentCost.price}
          info={
            displayShippingInfoPopup
              ? <FormattedMessage {...messages.shippingPopupInfo} />
              : null
          }
          className={classNames(styles.priceBreakdownLine, styles.orderTotalsBreakdown)}
        />
      )}

      {orderTaxes && (
        orderTaxes.map((tax, index) => (
          <PriceBreakdownLine
            id='orderTaxes'
            key={index}
            label={tax.label}
            price={tax.price}
            className={classNames(styles.priceBreakdownLine, styles.orderTotalsBreakdown)}
          />
        ))
      )}

      {grandTotal && (
        <PriceBreakdownLine
          id='grandTotal'
          label={grandTotal.label}
          price={grandTotal.price}
          className={classNames(
            styles.priceBreakdownLine,
            styles.priceBreakdownHeadLine,
            styles.orderTotalsBreakdown,
          )}
          bold
          firstOfOrder
        />
      )}
    </div>
  );
});

GiftcardOrderTotals.displayName = 'GiftcardOrderTotals';
