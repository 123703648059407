import React from 'react';

import { GlobalMessages } from '@customTypes/Messages';
import {
  HGC_StatementPage,
  HGC_TermsConditionsPage,
  Region,
  Hotel,
  HGC_GiftCardProductPage,
  HGC_BusinessRequestPage,
  HGC_HomePage_v2,
  HGC_BusinessRequestPage_v2,
  HGC_LandingPage,
  HGC_CardBalanceCheckPage,
  HGC_BlogOverviewPage,
  HGC_BlogArticle,
} from '@customTypes/apiTypes';

import { messageHooks } from '../messageHooks/messageHooks';
import { navigationHooks } from '../navigationHooks/navigationHooks';

export const entityHooks = {
  useRefs() {
    const previousLoadingRef = React.useRef(false);

    return {
      previousLoadingRef,
    };
  },
  useHomeV2Entity(): HGC_HomePage_v2 | null {
    const entity = messageHooks.useSubjectState(GlobalMessages.Entity);

    return entity?.data?.entity?.__typename === 'HGC_HomePage_v2' ? entity.data.entity : null;
  },
  useHotelListEntity(): Region | null {
    const entity = messageHooks.useSubjectState(GlobalMessages.Entity);

    return entity?.data?.entity?.__typename === 'Region' ? entity.data.entity : null;
  },
  useHotelEntity(): Hotel | null {
    const entity = messageHooks.useSubjectState(GlobalMessages.Entity);

    return entity?.data?.entity?.__typename === 'Hotel' ? entity.data.entity : null;
  },
  useCookiesAndPrivacyEntity(): HGC_StatementPage | null {
    const entity = messageHooks.useSubjectState(GlobalMessages.Entity);

    return entity?.data?.entity?.__typename === 'HGC_StatementPage' ? entity.data.entity : null;
  },
  useTermsAndConditionsEntity(): HGC_TermsConditionsPage | null {
    const entity = messageHooks.useSubjectState(GlobalMessages.Entity);

    return entity?.data?.entity?.__typename === 'HGC_TermsConditionsPage'
      ? entity.data.entity
      : null;
  },
  useRedirectAfterLoadedNullEntity(entityExists: boolean | null) {
    const entityLoading = messageHooks.useSubjectState(GlobalMessages.EntityLoading);
    const notFoundPageRedirect = navigationHooks.useNotFoundPageRedirect();

    const { previousLoadingRef } = entityHooks.useRefs();
    React.useEffect(() => {
      if (!entityExists && !entityLoading && previousLoadingRef.current) {
        window.location.href = notFoundPageRedirect();
      }

      previousLoadingRef.current = entityLoading || false;
    }, [entityExists, entityLoading, notFoundPageRedirect, previousLoadingRef]);
  },
  useHotelGiftCardPageEntity(): HGC_GiftCardProductPage | null {
    const entity = messageHooks.useSubjectState(GlobalMessages.Entity);

    return entity?.data?.entity?.__typename === 'HGC_GiftCardProductPage'
      ? entity.data.entity
      : null;
  },

  useBusinessPageEntity(): HGC_BusinessRequestPage | null {
    const entity = messageHooks.useSubjectState(GlobalMessages.Entity);

    return entity?.data?.entity?.__typename === 'HGC_BusinessRequestPage'
      ? entity.data.entity
      : null;
  },

  useBusinessPageEntityV2(): HGC_BusinessRequestPage_v2 | null {
    const entity = messageHooks.useSubjectState(GlobalMessages.Entity);

    return entity?.data?.entity?.__typename === 'HGC_BusinessRequestPage_v2'
      ? entity.data.entity
      : null;
  },

  useLandingPageEntity(): HGC_LandingPage | null {
    const entity = messageHooks.useSubjectState(GlobalMessages.Entity);

    return entity?.data?.entity?.__typename === 'HGC_LandingPage'
      ? entity.data.entity
      : null;
  },
  useBalanceCheckPageEntity(): HGC_CardBalanceCheckPage | null {
    const entity = messageHooks.useSubjectState(GlobalMessages.Entity);

    return entity?.data?.entity?.__typename === 'HGC_CardBalanceCheckPage'
      ? entity.data.entity
      : null;
  },
  useBlogOverviewPageEntity(): HGC_BlogOverviewPage | null {
    const entity = messageHooks.useSubjectState(GlobalMessages.Entity);

    return entity?.data?.entity?.__typename === 'HGC_BlogOverviewPage'
      ? entity.data.entity
      : null;
  },
  useBlogArticlePageEntity(): HGC_BlogArticle | null {
    const entity = messageHooks.useSubjectState(GlobalMessages.Entity);

    return entity?.data?.entity?.__typename === 'HGC_BlogArticle'
      ? entity.data.entity
      : null;
  },
};
