import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import classnames from 'classnames';

import { callbackHooks } from '@nxte-nl/callback-hooks';

import { GiftcardBasketItemWrapContainer }
  from '@containers/GiftcardBasketItemWrapContainer/GiftcardBasketItemWrapContainer';
import { DataLayerGiftCardProductV3 } from '@customTypes/DataLayer';
import { LayoutExtension } from '@customTypes/ResponsiveState';
import { Currency } from '@customTypes/apiEnums';
import { dataLayerV2Hooks } from '@hooks/dataLayerV2Hooks/dataLayerV2Hooks';
import { domHooks } from '@hooks/domHooks/domHooks';
import { undoBasketHooks } from '@hooks/undoBasketHooks/undoBasketHooks';

import { BasketModalButtons } from '../BasketModalButtons/BasketModalButtons';
import { Button } from '../Button/Button';
import {
  GiftCardBasketItem,
  Status,
} from '../GiftcardBasketItem/GiftcardBasketItem';
import { GiftcardOrderTotals, TotalsItem } from '../GiftcardOrderTotals/GiftcardOrderTotals';
import { Typography } from '../Typography/Typography';
import styles from './BasketModalContent.module.css';

const messages = defineMessages({
  emptyBasketTitle: {
    id: 'src.components.BasketModalContent.emptyBasketTitle',
    defaultMessage: 'Je winkelmandje is leeg',
  },
  emptyBasketDescription: {
    id: 'src.components.BasketModalContent.emptyBasketDescription',
    defaultMessage: 'Bestel een cadeaubon',
  },
  emptyBasketButton: {
    id: 'src.components.BasketModalContent.emptyBasketButton',
    defaultMessage: 'Cadeaubon toevoegen',
  },
});

export const hooks = {
  useItemsWithStatusState(defaultItems?: GiftCardBasketItem[] | null) {
    return React.useState<GiftCardBasketItem[] | null>(
      defaultItems?.map((item) => ({ ...item, status: Status.Holding })) ?? null,
    );
  },
  useContinueButtonClickHandler(
    allItemsDeleted: boolean,
    trackBeginCheckout: () => void,
    onContinueButtonClick?: () => void,
  ) {
    return React.useCallback(() => {
      if (allItemsDeleted || !onContinueButtonClick) return;
      trackBeginCheckout();
      onContinueButtonClick();
    }, [allItemsDeleted, trackBeginCheckout, onContinueButtonClick]);
  },
};

export type Props = {
  continueLoading: boolean;
  currency?: Currency | null;
  disabled: boolean;
  grandTotal: TotalsItem | null;
  items: GiftCardBasketItem[] | null;
  layout?: LayoutExtension;
  mappedItems: DataLayerGiftCardProductV3[] | null;
  onContinueButtonClick?: () => void;
  onDeleteGiftcardPackage: (packageId: string) => void;
  onDeleteOrderItem: (itemID: string) => void;
  onGiftcardPackageChange: (giftCardBasketItem: GiftCardBasketItem) => void;
  onSecondaryButtonClick: () => void;
  onUnDeleteGiftcardPackage: (giftCardBasketItem: GiftCardBasketItem) => void;
  onUnDeleteOrderItem:
    (itemSKU: string, personalMessageText: string | null, packageID: string) => void;
  orderTaxes: TotalsItem[] | null;
  revenue: number;
  shipmentCost: TotalsItem | null;
};

export const BasketModalContent = React.memo((props: Props) => {
  const {
    continueLoading,
    disabled,
    onContinueButtonClick,
    onSecondaryButtonClick,
    onGiftcardPackageChange,
    onDeleteOrderItem,
    onUnDeleteOrderItem,
    orderTaxes,
    grandTotal,
    shipmentCost,
    items,
    onDeleteGiftcardPackage,
    onUnDeleteGiftcardPackage,
    layout,
    mappedItems,
    revenue,
    currency,
  } = props;

  const [itemsWithStatus, setItemsWithStatus] = hooks.useItemsWithStatusState(items);

  const {
    restoredIdRef,
  } = undoBasketHooks.useItemsMap(items, setItemsWithStatus);

  const { onDeletePackage } =
    undoBasketHooks.useDeletePackages(setItemsWithStatus, onDeleteGiftcardPackage);

  const { onUnDeletePackage } =
    undoBasketHooks.useUnDeletePackages(setItemsWithStatus, onUnDeleteGiftcardPackage);

  const allItemsDeleted = !itemsWithStatus?.some(x => x.status !== Status.Deleted) || false;

  const trackBeginCheckout =
    dataLayerV2Hooks.useTrackBeginCheckout(mappedItems, revenue, currency || Currency.EUR);
  const clearDataLayer = dataLayerV2Hooks.useClearDataLayer();
  const hadnleDataLayerSetting =
  callbackHooks.useWrapCallbacks([clearDataLayer, trackBeginCheckout]);

  const isMobileTablet = layout === LayoutExtension.Mobile || layout === LayoutExtension.Tablet;
  const { isScrollPresent, refCallback } = domHooks.useScrollPresent(isMobileTablet);

  const handleContinueButtonClick = hooks.useContinueButtonClickHandler(
    allItemsDeleted,
    hadnleDataLayerSetting,
    onContinueButtonClick,
  );

  const proceedToCheckout = React.useMemo(() =>
    Boolean(!allItemsDeleted && onContinueButtonClick), [allItemsDeleted, onContinueButtonClick]);

  return (
    <div className={styles.basketModalContent}>
      {itemsWithStatus?.length
        ? (
          <div>
            <div ref={refCallback} className={classnames(styles.basketPackagesList, {
              [styles.scrollGap]: isScrollPresent,
            })}>
              {
                itemsWithStatus.map((basketItem) => {
                  return (
                    <GiftcardBasketItemWrapContainer
                      key={basketItem.id}
                      data={basketItem}
                      disabled={disabled}
                      onDeletePackage={onDeletePackage}
                      onUnDeletePackage={onUnDeletePackage}
                      onGiftcardPackageChange={onGiftcardPackageChange}
                      onDeleteOrderItem={onDeleteOrderItem}
                      onUnDeleteOrderItem={onUnDeleteOrderItem}
                      restoredIdRef={restoredIdRef}
                    />
                  );
                })
              }
            </div>
            <div className={styles.totalsBlock}>
              <GiftcardOrderTotals
                orderTaxes={orderTaxes}
                grandTotal={grandTotal}
                shipmentCost={shipmentCost}
              />
            </div>
            <div className={styles.basketControlButtons}>
              <BasketModalButtons
                onContinueButtonClick={handleContinueButtonClick}
                proceedToCheckout={proceedToCheckout}
                continueLoading={continueLoading}
                onSecondaryButtonClick={onSecondaryButtonClick}
                layout={layout}
              />
            </div>
          </div>
        )
        : <div className={styles.emptyBasketBlock}>
          <Typography variant='body4' className={styles.emptyBasketTitle}>
            <FormattedMessage {...messages.emptyBasketTitle} />
          </Typography>
          <Typography variant='body4' className={styles.emptyBasketDescription}>
            <FormattedMessage {...messages.emptyBasketDescription} />
          </Typography>
          <Button
            intent='primaryV2'
            size='medium'
            onClick={onSecondaryButtonClick}
          >
            <FormattedMessage {...messages.emptyBasketButton} />
          </Button>
        </div>
      }

    </div>
  );
});

BasketModalContent.displayName = 'BasketModalContent';
