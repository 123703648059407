
import { apolloHooks } from '@nxte-nl/apollo-hooks';
import { callbackHooks } from '@nxte-nl/callback-hooks';

import { GiftCardCheckoutMessages } from '@customTypes/Messages';
import { ApiGiftCardOrderResponse } from '@customTypes/apiCompound';
import { OrderFlow } from '@customTypes/apiTypes';
import { messageHooks } from '@hooks/messageHooks/messageHooks';
import { giftcardBasket } from '@queries/giftcardBasket.gql';

export const hooks = {
  useBasket() {
    const setBasket = messageHooks.useNext(GiftCardCheckoutMessages.GiftcardBasket);

    const { loading } = apolloHooks.useQuery<ApiGiftCardOrderResponse>(giftcardBasket, {
      onCompleted: (data) => setBasket(data),
      context: { orderFlow: OrderFlow.giftcard_order },
    });

    return { loading };
  },
};

export const GiftcardBasketContainer = () => {
  const { loading } = hooks.useBasket();
  const setLoading = messageHooks.useNextWithValue(
    GiftCardCheckoutMessages.GiftcardBasketLoading, loading,
  );
  callbackHooks.useEffectCallback(setLoading);
  return null;
};

GiftcardBasketContainer.displayName = 'GiftcardBasketContainer';
