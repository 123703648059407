import React from 'react';

import classnames from 'classnames';

import { CircleButton } from '../CircleButton/CircleButton';
import { Input } from '../Input/Input';
import styles from './NumberPicker.module.css';

export type NumberChangedCallback = (value: number) => void;

export const hooks = {
  useNumber(value: number, onChanged: NumberChangedCallback) {
    const increment = React.useCallback(() => {
      onChanged(value + 1);
    }, [value, onChanged]);

    const decrement = React.useCallback(() => {
      onChanged(value - 1);
    }, [value, onChanged]);

    return { increment, decrement };
  },
  useHandleInputChange(max: number, min: number, onChanged: NumberChangedCallback) {
    return React.useCallback((evt: React.FormEvent<HTMLInputElement>) => {
      const value = Number(evt.currentTarget.value);
      onChanged(value > max ? max : value < min ? min : value);
    }, [onChanged, max, min]);
  },
};

export type Props = {
  disabled?: boolean;
  intent?: 'default' | 'input';
  max?: number;
  min: number;
  onChanged: NumberChangedCallback;
  size?: 'small' | 'medium';
  value: number;
};
export const NumberPicker = React.memo((props: Props) => {
  const {
    value,
    min = 0,
    max = Number.POSITIVE_INFINITY,
    intent = 'default',
    size = 'medium',
    onChanged,
    disabled,
  } = props;

  const { increment, decrement } = hooks.useNumber(value, onChanged);
  const handleInputChange = hooks.useHandleInputChange(max, min, onChanged);

  return (
    <div className={classnames(styles.numberPicker, styles[size])}>
      <CircleButton
        disabled={value === min || disabled}
        onClick={decrement}
        className={styles.button}
        size={size}
      >
        -
      </CircleButton>
      {intent === 'default' ? <div className={styles.value}>{value}</div> : null}
      {intent === 'input' ? <div className={styles.inputContainer}>
        <Input
          inputClassName={styles.inputValue}
          inputSize='large'
          min={min}
          max={max}
          type='number'
          value={value}
          disabled={disabled}
          onChange={handleInputChange}
        />
      </div> : null}
      <CircleButton
        disabled={max === value || disabled}
        onClick={increment}
        className={styles.button}
        size={size}
      >
        +
      </CircleButton>
    </div>
  );
});

NumberPicker.displayName = 'NumberPicker';
